import React from 'react';
import { BrowserRouter as Router, Routes,  Route, Link } from 'react-router-dom';
import BookingPage from './app/pages/booking/booking';
import './App.css';
import { ToastContainer } from 'react-toastify';
import PaymentConfirmationModal from './app/components/confirmation/confirmation.modal';


function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<BookingPage />} />
          <Route path='/confirmation' element={<PaymentConfirmationModal />} />
        </Routes>
      </Router>
      <ToastContainer draggable='true' pauseOnFocusLoss='true' autoClose='20000' position='top-center' />
    </div>
  );
}

export default App;
