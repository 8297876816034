/* Dev Name: Valentine Bassey. 
   Email: awasevalentine@gmail.com
   Github: https://github.com/awasevalentine
*/


import { BiMinus } from 'react-icons/bi';
import {usePaystackPayment} from 'react-paystack';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import FormValidation from '../../components/form-validation/form-validation';
import { toast } from "react-toastify";
import { hotelRoomType,hotelRoomCategory, initialValues, personCategory, personGender } from '../../models/booking-form';
import ReservationService from '../../services/available-room.service'
import './booking.css';
import Loader from '../../components/loader/loader';



const BookingPage = () => {

    var today = new Date().toISOString().split('T')[0];// for disabling the previous date from being clickable
    const navigate = useNavigate()
    const [booking, setBooking] = useState(initialValues);
    var [daysInHotel, setDaysInHotel] = useState(0);
    const [loading, setLoading ] = useState(false);
    const [newAmount, setNewAmount] = useState(0)


    
  //Getting the available rooms for any selected category.
    async function getAvailRooms(data){
    var {roomCategory, roomType, roomQuantity } = data
    if(roomCategory !== '' && roomType !== ''){
        setLoading(true)
       return ReservationService.getAvailableRooms({roomCategory,roomType}).then((res)=>{
            if(roomQuantity > res.roomsLeft){
                setLoading(false)
                toast.error(`Sorry, only ${res.roomsLeft} rooms are available for the selected room category at this time.  
                    Please choose a different room category or reduce the number of rooms you want to book.`);
     
            } else{
                    setLoading(false)
                    return true
            }
      
        })
    }
}


/* This section initializes the payment process */
    const config = {
        reference: "HBH-" +(new Date()).getTime().toString(),
        email: booking.email,
        amount: newAmount+"00",
        publicKey: "pk_live_5f747ca7744b136fbbef03555fe1eb0076be7016",
        // publicKey: process.env.REACT_APP_PUBLICKEY,
        firstname: booking.firstName,
        lastname: booking.otherNames,
    };

    var initializePayment = usePaystackPayment(config);
/* End of payment initialization process */


//This method is use in checking when the value of the input fields changes
    function textChange(e){
        setBooking((prevData)=>({
            ...prevData,
            [e.target.name]: e.target.value
        }))

    }

const getDiscount = (amount, discountPrice) =>{
    const discount = amount/100 * discountPrice
    return amount - discount
}


/*Section for Payment initialization */
    function handlePayment(){
                const onSuccess = (reference) => {
                    booking.transactionRef = reference.reference
                    booking.transaction = reference.transaction
                    booking.amount = newAmount
                    ReservationService.createReservation(booking).then((res)=>{
                    },
                    (err)=>{
                        toast.error(err.message);
                    })
                    navigate('/confirmation', {state: booking})
                };
        
                const onClose = () => {
                    // setBooking(initialValues)
                    navigate('/', {state: booking})

                }
                return initializePayment(onSuccess, onClose);
    }    
/*End of Section for Payment initialization */


/* Section for handling form submission*/
    const [formErrors, setFormErrors] = useState({});

    const handleSubmit = async (e) => {
        e.preventDefault();
        setFormErrors(FormValidation(booking));
        if (Object.keys(formErrors).length === 0) {
            booking.amount = newAmount
           return await getAvailRooms(booking).then((res)=>{
                if(res){
                    handlePayment()
                }
            },
            (err)=>{
                toast.error(err.message);
            })
        }
    };
/* End of Section for handling form submission*/

  
/* Section for React useEffect */
  useEffect(() => {
        let tempDaysInHotel = (new Date(booking.checkOutDate) - new Date(booking.checkInDate)) / (1000 * 60 * 60 * 24);
        tempDaysInHotel > 0 ? setDaysInHotel(tempDaysInHotel): setDaysInHotel(tempDaysInHotel + 1);

        //Section for assigning Amount the selected the Room
        switch(booking.roomCategory) {
            case 'Single Deluxe':
                    if(booking.roomType === 'Smoking') {
                        setNewAmount(getDiscount(40500, 10) * daysInHotel * booking.roomQuantity)
                    } 
                    // if(booking.roomType === 'Non Smoking') {
                    //     setNewAmount(22500 * daysInHotel * booking.roomQuantity)
                    //     // setNewAmount(25000 * daysInHotel * booking.roomQuantity)
                    // }
                break;
            case 'Standard Deluxe':
                    if(booking.roomType === 'Smoking') {
                        setNewAmount(getDiscount(47500, 10) * daysInHotel * booking.roomQuantity)
                        // setNewAmount(35000 * daysInHotel * booking.roomQuantity)
                    } else {
                        setNewAmount(getDiscount(40500, 10) * daysInHotel * booking.roomQuantity)
                        // setNewAmount(30000 * daysInHotel * booking.roomQuantity)
                    }
                break;
            case 'Luxury':
                    if(booking.roomType === 'Smoking') {
                        setNewAmount(getDiscount(55500, 10) * daysInHotel * booking.roomQuantity)
                        // setNewAmount(43000 * daysInHotel * booking.roomQuantity)

                    } else {
                        setNewAmount(getDiscount(50500, 10) * daysInHotel * booking.roomQuantity)
                        // setNewAmount(38000 * daysInHotel * booking.roomQuantity)
                    }
                break;
            case 'Honey Suite':
                    if(booking.roomType === 'Smoking') {
                        setNewAmount(getDiscount(95500, 10) * daysInHotel * booking.roomQuantity)
                        // setNewAmount(50000 * daysInHotel * booking.roomQuantity)
                    } else {
                        setNewAmount(getDiscount(93500, 10) * daysInHotel * booking.roomQuantity)
                        // setNewAmount(45000 * daysInHotel * booking.roomQuantity)
                    }
                    break;

            case 'Executive':
                    if(booking.roomType === 'Smoking') {
                        setNewAmount(getDiscount(78500, 10) * daysInHotel * booking.roomQuantity)
                        // setNewAmount(50000 * daysInHotel * booking.roomQuantity)
                    } else {
                        setNewAmount(getDiscount(73500, 10) * daysInHotel * booking.roomQuantity)
                        // setNewAmount(45000 * daysInHotel * booking.roomQuantity)
                    }
                break;

            case 'Hillsbay Classic':
                    // if(booking.roomType === 'Smoking') {
                    //     setNewAmount(45000 * daysInHotel * booking.roomQuantity)
                    //     // setNewAmount(50000 * daysInHotel * booking.roomQuantity)
                    // } else {
                        setNewAmount(getDiscount(60500, 10) * daysInHotel * booking.roomQuantity)
                        // setNewAmount(45000 * daysInHotel * booking.roomQuantity)
                    // }
                break;
            default:
            break;
        }
  }, [formErrors,booking, daysInHotel]);
/* End of Section for React useEffect */

 

    return ( 
        <div className="booking-page">
            {
        
            loading && <Loader />
            }

        <div className="container">
            <a href='https://www.hillsbaygroup.com'>
                <img className='hillsbay_logo' src={require('../../../assets/logo/hillsbay_logo.png')} alt="logo" />
            </a>
            <div className="header_wrapper">
                <div className="sub_header_wrapper">
                    <h2 className='text-center welcome-header'>Hillsbay Reservation</h2>
                    <h4 className='sub-welcome-header'>Where Luxury Comes Naturally</h4>
                </div>
            </div>
            <div id='main-content-wrapper'>
            <form onSubmit={handleSubmit}>
                <section id='profile-section'>
                <div class="header">
                    <h4 class="header-content">Personal Information</h4>
                    <hr />
                </div>
                <div class="row profile-content">
                    <div class="input-group-sm col-sm-12 col-md-6 form-field ">
                        <label htmlFor='FirstName'>First Name <span> *</span></label>
                        <input type='text' id='firstName' 
                            name='firstName' className='form-control'
                            value={booking.firstName}
                            onChange={textChange}
                        />
                        <p className='formError-wrapper'>{formErrors.firstName}</p>
                    </div>
                    <div class="input-group-sm col-sm-12 col-md-6 form-field has-validation">
                        <label htmlFor='otherNames'>Other Names <span> *</span></label>
                        <input type='text' id='otherNames' 
                            name='otherNames' className='form-control'
                            value={booking.otherNames}
                            onChange={textChange}
                        />
                        <p className='formError-wrapper'>{formErrors.otherNames}</p>
                    </div>
                    <div class="input-group-sm col-sm-12 col-md-6 form-field has-validation">
                        <label htmlFor='phone'>Phone Number <span> *</span></label>
                        <input type='tel' id='phone' 
                            name='phone' className='form-control'
                            value={booking.phone}
                            onChange={textChange}
                        />
                        <p className='formError-wrapper'>{formErrors.phone}</p>
                    </div>
                    <div class="input-group-sm col-sm-12 col-md-6 form-field has-validation">
                        <label htmlFor='email'>Email Address <span> *</span></label>
                        <input type='email' id='email' 
                            name='email' className='form-control'
                            value={booking.email}
                            onChange={textChange}
                        />
                        <p className='formError-wrapper'>{formErrors.email}</p>
                    </div>
                    <div class="input-group-sm col-sm-12 col-md-6 form-field">
                        <label htmlFor='gender'>Gender</label>
                        <select className='form-select' id='gender' name='gender'
                            onChange={textChange}>
                            <option selected >Choose...</option>
                            {
                                personGender.map((gender, index) => (
                                    <option key={index} value={gender}>{gender}</option>
                                ))
                            }
                        </select>
                    </div>
                    <div class="input-group-sm col-sm-12 col-md-6 form-field">
                        <label htmlFor='category'>Person Category</label>
                        <select className='form-select' name='personCategory' id='personCategory' onChange={(e) => {textChange(e)}}>
                            <option selected >Choose...</option>
                            {
                                personCategory.map((category, index) => (
                                    <option key={index} value={category}>{category}</option>
                                ))
                            }
                        </select>
                    </div>
            
                </div>
                </section>

                {/* Section for fillig the room details type */}
                <section id='room-detail-section'>
                <div class="header">
                    <h4 class="header-content">Room Information</h4>
                    <hr />
                </div>
                <div class="row room-content">
                    <div class="input-group-sm col-sm-12 col-md-6 form-field has-validation">
                        <label htmlFor='check-in-date'>Arrival Date <span> *</span></label>
                        <input type='date' id='checkInDate' 
                            name='checkInDate' className='form-control'
                            value={booking.checkInDate}
                            min={today}
                            onChange={textChange}
                        />
                        <p className='formError-wrapper'>{formErrors.checkInDate}</p>
                    </div>
                    <div class="input-group-sm col-sm-12 col-md-6 form-field has-validation">
                        <label htmlFor='check-out-date'>Departure Date <span> *</span></label>
                        <input type='date' id='checkOutDate' 
                            name='checkOutDate' className='form-control'
                            value={booking.checkOutDate}
                            min={today}
                            onChange={textChange}
                        />
                        <p className='formError-wrapper'>{formErrors.checkOutDate}</p>
                    </div>
                    <div class="input-group-sm col-sm-12 col-md-6 form-field has-validation">
                        <label htmlFor='roomCategory'>Room Category <span> *</span></label>
                        <select className='form-select' id='roomCategory' name='roomCategory'
                            value={booking.roomCategory}
                            onChange={textChange} >
                            <option selected >Choose...</option>
                            {
                                hotelRoomCategory.map((roomCategory, index) => (
                                    <option key={index} value={roomCategory.roomCategory}>{roomCategory.roomCategory}</option>
                                ))
                            }
                        </select>
                        <p className='formError-wrapper'>{formErrors.roomCategory}</p>
                    </div>
                    <div class="input-group-sm col-sm-12 col-md-6 form-field has-validation">
                        <label htmlFor='roomType'>Room Type <span> *</span></label>
                        <select className='form-select' id='roomType' name='roomType'
                            value={booking.roomType}
                            onChange={textChange} >
                            <option selected >Choose...</option>
                            {
                                hotelRoomType.map((roomType, index) => (
                                    <option key={index} value={roomType}>{roomType}</option>
                                ))
                            }
                        </select>
                        <p className='formError-wrapper'>{formErrors.roomType}</p>
                    </div>
                    <div class="input-group-sm col-sm-12 col-md-6 form-field">
                        <label htmlFor="plus-minus">Number of Rooms</label>
                        <br />
                        <div className="plus-minus-wrapper form-control">
                        <BiMinus onClick={(e)=> setBooking({ ...booking, roomQuantity: booking.roomQuantity - 1 })} className='decrementBtn'/> 
                                <input type='text' className='count-content-wrapper'
                                name='roomQuantity' id='roomQuantity'
                                min={1} minLength={1}
                                onChange={textChange}
                                value={booking.roomQuantity}
                                /> 
                        <span onClick={(e)=> setBooking({ ...booking, roomQuantity: booking.roomQuantity + 1 })} className='incrementBtn'> + </span>
                        </div>    
                        <p className='formError-wrapper'>{formErrors.roomQuantity}</p>

                    </div>
                    <div class="input-group-sm col-sm-12 form-field">
                        <label htmlFor='additionalRequest'>Additional Request</label>
                        < textarea class="form-control" aria-label="With textarea" 
                        name='additionalRequest' id='additionalRequest'
                        placeholder='If you have any special request, please write it here'
                        onChange={textChange} style={{height: '150px'}} >

                        </textarea>
                    </div>
            
                </div>
                <div className='total-wrapper mt-4'>
                    <p className='text-center'>Total: ₦{newAmount} </p>
                    <p class="promo_message">Congratulations! You are on a 10% discount for any room category</p>
                </div>
                <div class='btn_wrapper'>
                    <button type='submit' className='btn btn-ascern paymentBtn'> 
                        Payment
                    </button>
                </div>
                </section>
            </form>
            </div>
        </div>
        </div>
     );
}
 
export default BookingPage;